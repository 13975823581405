
import { defineComponent } from '@vue/composition-api'

import AppBar from '@/layouts/default/app-bar.vue'

export default defineComponent({
  name: 'public-layout',
  components: {
    AppBar,
  },
})
